import { Box, Stack, Typography } from "@mui/material";
import { SingleTextFieldSubmit } from "./SingleTextFieldSubmit";
import { SignInWithApp } from "./SignInWithApp";

import "./CardContentSignIn.scss";
import { SignInMethodConfig } from "../types/SignInMethod";
import CPLink from "./CPLink";
import { CPCard } from "./CPCard";

interface CardContentSignInProps {
  signInMethodConfig?: SignInMethodConfig[];
  enabledEmailSignin?: boolean;
  onClickEmailSignin?: (email: string) => void;
  onClickTeamDomain?: () => void;
  email?: string;
  teamDomain?: string;
  emailDomain?: string;
  teamSelect?: boolean;
  selfSignup?: boolean;
  onClickJoinTeam?: () => void;
  errorMessage?: string;
  loading?: boolean;
}

const DisplayMode = {
  all: "all",
  email_only: "email_only",
  app_only: "app_only",
} as const;

const getDisplayMode = (signInMethodConfig: SignInMethodConfig[], enabledEmailSignin: boolean) => {
  const enabledAppSignin = signInMethodConfig.length > 0;
  if (enabledAppSignin && enabledEmailSignin) {
    return DisplayMode.all;
  } else if (enabledEmailSignin) {
    return DisplayMode.email_only;
  } else if (enabledAppSignin) {
    return DisplayMode.app_only;
  }

  return DisplayMode.all;
}

export const CardContentSignIn = ({
  signInMethodConfig = [],
  enabledEmailSignin = true,
  teamDomain = "",
  teamSelect = false,
  selfSignup = false,
  ...props
}: CardContentSignInProps) => {
  const displayMode = getDisplayMode(signInMethodConfig, enabledEmailSignin);
  const showAll = displayMode === DisplayMode.all;
  const showEmailSignin = displayMode === DisplayMode.all || displayMode === DisplayMode.email_only;
  const showAppSignin = displayMode === DisplayMode.all || displayMode === DisplayMode.app_only;
  const showDivider = displayMode === DisplayMode.all;

  return (
    <CPCard>
      <Stack spacing={2} className="w-100">
        {showEmailSignin && (
          <>
            <SingleTextFieldSubmit
              title="Sign in"
              label="email"
              hint={teamDomain}
              labelButton="next"
              value={props.email}
              errorMessage={props.errorMessage}
              loading={props.loading}
              onClick={props.onClickEmailSignin}/>

            {teamSelect && <CPLink
              label="Sign in to another team"
              onClick={props.onClickTeamDomain} />
            }

            {selfSignup &&
              <Box>
                <Typography sx={{ fontSize: '14px' }}>
                  Have an @<b>{props.emailDomain}</b> email address?
                </Typography>

                <CPLink
                  prefix="You can"
                  label="join the team"
                  spacing={0.5}
                  onClick={props.onClickJoinTeam} />
              </Box>
            }
          </>
        )}
        {showAppSignin && (
          <>
            <hr
              hidden={!showDivider}
              className="horizontal-divider mx-8"
            />
            <Box className="w-100">
              <SignInWithApp config={signInMethodConfig} />
            </Box>
          </>
        )}
      </Stack>
    </CPCard>
  );
};
