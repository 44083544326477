import { Stack, FormHelperText, Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface CPFormHelperTextProps {
  id: string;
  message: string;
  error?: boolean;
}

export default function CPFormHelperText({
  id,
  message,
  error = false,
}: CPFormHelperTextProps) {
  if (message.length === 0) {
    return null;
  }

  const color = error ? 'error' : 'success';
  const icon = error 
              ? <CancelIcon color={color} sx={{ width: 16, height: 16 }}/> 
              : <CheckCircleIcon color={color} sx={{ width: 16, height: 16 }}/>;

  return (
    <FormHelperText 
      id={id} 
      error={error}
      component="div"
    >
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {icon}
        <Typography color={color} fontSize={14}>{message}</Typography>
      </Stack>
    </FormHelperText>
  );
};