import { Buffer } from "buffer";
import { get } from "./storage";

export const composeUri = (baseUri: string, region: string | undefined, teamDomain: string | undefined, user: any) => {
    const team_domain = get("team_domain") || teamDomain || "";
    const context = get("context") || "";
    const savedRegion = get("region") || region || "";

    const data = { 
        "access_token": user.accessToken.jwtToken,
        "id_token": user.idToken.jwtToken,
        "refresh_token": user.refreshToken.token,
        "expires_in": "3600",
        "token_type": "Bearer",
    };
    const base64Data = Buffer.from(JSON.stringify(data)).toString('base64');

    const url = new URL(baseUri);
    const params = url.searchParams;
    params.append("team_domain", team_domain);
    params.append("region", savedRegion);
    params.append("state", base64Data);
    params.append("context", context);

    return url.toString();
}

// CognitoUser example
/*
{
    "username": "onelogin_dominik.oh@cupix.com",
    "pool": {
        "userPoolId": "ap-northeast-2_6HV13usnG",
        "clientId": "3c9a3onons9u001crglgbnk32i",
        "client": {
            "endpoint": "https://cognito-idp.ap-northeast-2.amazonaws.com/",
            "fetchOptions": {}
        },
        "advancedSecurityDataCollectionFlag": true,
        "storage": {
            "domain": "localhost",
            "path": "/",
            "expires": 365,
            "secure": true,
            "sameSite": "strict"
        }
    },
    "Session": null,
    "client": {
        "endpoint": "https://cognito-idp.ap-northeast-2.amazonaws.com/",
        "fetchOptions": {}
    },
    "signInUserSession": {
        "idToken": {
            "jwtToken": "eyJraWQiOiJZK2FQanYyR1JaWlJlK0NuNjR5czN2WSttcVZsUjhhWWVJZVlVQmJQNFVBPSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoiQ2Q1N2lZY1ZBbFcxcHpNcVo1ODRYZyIsInN1YiI6ImQzZmE3MjY2LWJiYmItNDE0YS1hZjI0LTYyODJiYjkzMzhmMCIsImNvZ25pdG86Z3JvdXBzIjpbImFwLW5vcnRoZWFzdC0yXzZIVjEzdXNuR19vbmVsb2dpbiJdLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5hcC1ub3J0aGVhc3QtMi5hbWF6b25hd3MuY29tXC9hcC1ub3J0aGVhc3QtMl82SFYxM3VzbkciLCJjb2duaXRvOnVzZXJuYW1lIjoib25lbG9naW5fZG9taW5pay5vaEBjdXBpeC5jb20iLCJub25jZSI6Ik02R2xzdGI1TnphQVNjWXJKMXpFd1lpZnpzZWVQS3VYNW5HV193Rzc1a0JkWS1qRjBxSkc5QTBVNU5UcXV1VWdiYmE2M2k5ODUxU2NKWC1IOWRaNURGQlB5b2VpNjhaTEhMZWgyLTdHRENaSlZVUEFicXRhanZFRGtyWUdURWJ3dnU2bnNxNnVBNWQwaUtrQzI3WlRyNWRpTmV2eUFaSVE1ZjNDRmZWQTRyQSIsIm9yaWdpbl9qdGkiOiIwMjQyNmIyNi1jNTkwLTQ2ZGQtOGRhOS0xOGFkM2JjYTc2NjYiLCJhdWQiOiIzYzlhM29ub25zOXUwMDFjcmdsZ2JuazMyaSIsImlkZW50aXRpZXMiOlt7InVzZXJJZCI6ImRvbWluaWsub2hAY3VwaXguY29tIiwicHJvdmlkZXJOYW1lIjoib25lbG9naW4iLCJwcm92aWRlclR5cGUiOiJTQU1MIiwiaXNzdWVyIjoiaHR0cHM6XC9cL2FwcC5vbmVsb2dpbi5jb21cL3NhbWxcL21ldGFkYXRhXC9hODM1ODc5NC03NzI5LTQyZDktOGRlMS0zZWEzOWZlZGY0OTIiLCJwcmltYXJ5IjoidHJ1ZSIsImRhdGVDcmVhdGVkIjoiMTY5ODAxODgwNDY1MiJ9XSwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2OTk0OTkwNjAsImV4cCI6MTY5OTUwMjY2MCwiaWF0IjoxNjk5NDk5MDYwLCJqdGkiOiJhYjljMjJkNC04YmY1LTRlYWQtYTc1Ny0zNDE5YTVkODk5ZjAiLCJlbWFpbCI6ImRvbWluaWsub2hAY3VwaXguY29tIn0.0MF9CWxqH4erwzQLUsn8uEwgbWAUP0rKPrX2-c9JwRtNfA8SPcB2JIZcTkrGwZ1gmjHnmNilcpLjKx1r4PxcVZU8DJW-fCGkjpQ7A-8d89gJkMKTjkhK3kwOwgkyRl-0ho31hEX6YiS-e_NdEb7mHhbQYb8U3qgRZBrZD7Z8FSvFrjEpjR4xL1mXeiSj9UVypnmhoHaoXWirQS2I1F_jx6gQ3gg8LijQE9MR3ZaYoe7WTAe_nNxQAmEyxX8ZhhWorTvd8I9IydKAuu8Ip0lwnl4yXWIddWVbPF_AD4qWYaQKp_kIHDzZQ5bAQERkf6d7kIUvBEe7-tTDIWymSVjTyQ",
            "payload": {
                "at_hash": "Cd57iYcVAlW1pzMqZ584Xg",
                "sub": "d3fa7266-bbbb-414a-af24-6282bb9338f0",
                "cognito:groups": [
                    "ap-northeast-2_6HV13usnG_onelogin"
                ],
                "email_verified": false,
                "iss": "https://cognito-idp.ap-northeast-2.amazonaws.com/ap-northeast-2_6HV13usnG",
                "cognito:username": "onelogin_dominik.oh@cupix.com",
                "nonce": "M6Glstb5NzaAScYrJ1zEwYifzseePKuX5nGW_wG75kBdY-jF0qJG9A0U5NTquuUgbba63i9851ScJX-H9dZ5DFBPyoei68ZLHLeh2-7GDCZJVUPAbqtajvEDkrYGTEbwvu6nsq6uA5d0iKkC27ZTr5diNevyAZIQ5f3CFfVA4rA",
                "origin_jti": "02426b26-c590-46dd-8da9-18ad3bca7666",
                "aud": "3c9a3onons9u001crglgbnk32i",
                "identities": [
                    {
                        "userId": "dominik.oh@cupix.com",
                        "providerName": "onelogin",
                        "providerType": "SAML",
                        "issuer": "https://app.onelogin.com/saml/metadata/a8358794-7729-42d9-8de1-3ea39fedf492",
                        "primary": "true",
                        "dateCreated": "1698018804652"
                    }
                ],
                "token_use": "id",
                "auth_time": 1699499060,
                "exp": 1699502660,
                "iat": 1699499060,
                "jti": "ab9c22d4-8bf5-4ead-a757-3419a5d899f0",
                "email": "dominik.oh@cupix.com"
            }
        },
        "refreshToken": {
            "token": "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.jOe6Wg_VVQTo94avPPK-FORWqCSAX9OVsnXdqMsvW6A7PwLBSYT6zXkpvO_LGXMfeTD78qdg8QN6mvxn4nylcwF0iwMkL6QIpo5uobT7vtjqLMerY40pQ95b_QGclCBCQPSd3C7QL1zMLYCIeUop_cXREEXw1y2EFtGrjTM_WMNS0MF1cshg2U9QmXOTtNpQbrzMR--Tl7rFGAV7aNHrc968OiD8JacWtxt3YR9abZm9ngTDq79htoDdyKPnmKpVCM_Wwaxuu-Ss6tUFdUxVhL-Vr2_xiGWyFhjl4qaCS4S_ND-DL4-VjYNegj6BU51PIKwrlyp1EX0Dv9q8wmqAhA.Bqhtbv6JpNPGqyAL.QOSFHRr16o31B9vJlCmwr6y-5Kiu08RJmte7KO6wRXWWtr4dNMz1BzmMQI97AfWr7fMpvnMw59_j_HWidY5I6MxuUtmH0p9gS-OeungyyF31VPGs5LdAgL6RfyM15cKzVqaF8p1fCo-rAwyLu4zxS24Bi45gmxzwFS6ll3L38TUTx1iZBnHV_H8i3cOyFwMZ1kF2GQ8wCQe37GB1JW1GM4ho9j39IrK8fbiZZCqDJwzwidyLM_OJ7SEXaoKE75RGBHcUlsQqO_kfyJjRiUFP9D706F6C7nZUE_xh4CsLBXSYITxo9XAjtBDKdVgxYyFVDi3OxV7B4dsAHFkQg5tfCEaOixpWI-p6h0hm5nVFCpsdr6ob5PP9Eh19W5L_9HlPa1j-wE7B-Am-IT41BUNgzhjloko7SstkmExpL23avegJzj5T0fiBiTS24fWxorO0p6fovHZxRyaLOJW-m5Qj7Ut8EU3aWegxcTdmvBXj0QDlmOMvNDAr6TG1augQI17XV5w1CPWKDTolPS9MBunCjU4-GqBXXrfGWobeBmAJbmzpNxCEfyVJ7IUs-77fD3dTg0YjwK3Qkc4BI1mrbdCHE67cnUInVi57MGpZ8dSHEyjJGIQI_Tr2lVPi9M10_jAkZqOH46iVuh0q_vXIrtUV54eA7l-kwF60IcDJmGqMC1Sb3H3IveZMILXMFwPxj45SeO7DadkvyyKYc0qjktQFkRQBLEGipwDMbidTtsYRexkGvydkJ_ooXGieakwtz-usuq5DM7FwSpyiKotNI0Y4qLWpr0nJcS2YOJbdW8CGS_3-B7mBxYPgUQGELWyD1sQPAAjsXqztBMecOSglV33o6-mpFKrta02oNbY41_JmaqqZr9CGYovPPR67GtRAPpILUI4H3RpXHj757T8mmiXvsqdJBdZBZUgHvr-LTgWWmhVs_txO4K1Kvhf9o1smaLpm63yfO8IGWhmw_ndcfXGXcThSKghsdq73eS1HqvbUPobe-IILqYokZvWdYkZ1WV0KXLYEIYxgDV-t4BvMpT3IwAgobCqnMB28CCg2l__PgJAUir7geNMvgYPtEDkRwo4bRn8Qcyq9FudXxdRU5nrmhB0LbG9sbWYdPmA_CSbpX04BSXdkf_sONcBxDNF2eIJh3UAMaUErYBymgz5ZOcrduH67jjmi9-R9lSvmE1x5Ou3d4GdTW0L8PIBbO7UfOrYmSP_hGyz1uU1ZMJ_-POD91DMKn2w4_eW-3sKuJjHS.9uJSxg_y87EXDaip1WKatg"
        },
        "accessToken": {
            "jwtToken": "eyJraWQiOiIzRlI3ZlBaS2VXU0FVWUIrWXJPMkFxRlpORWhcL3JEMXlvank3Y0lZSzNxUT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJkM2ZhNzI2Ni1iYmJiLTQxNGEtYWYyNC02MjgyYmI5MzM4ZjAiLCJjb2duaXRvOmdyb3VwcyI6WyJhcC1ub3J0aGVhc3QtMl82SFYxM3Vzbkdfb25lbG9naW4iXSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmFwLW5vcnRoZWFzdC0yLmFtYXpvbmF3cy5jb21cL2FwLW5vcnRoZWFzdC0yXzZIVjEzdXNuRyIsInZlcnNpb24iOjIsImNsaWVudF9pZCI6IjNjOWEzb25vbnM5dTAwMWNyZ2xnYm5rMzJpIiwib3JpZ2luX2p0aSI6IjAyNDI2YjI2LWM1OTAtNDZkZC04ZGE5LTE4YWQzYmNhNzY2NiIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoicGhvbmUgb3BlbmlkIGVtYWlsIiwiYXV0aF90aW1lIjoxNjk5NDk5MDYwLCJleHAiOjE2OTk1MDI2NjAsImlhdCI6MTY5OTQ5OTA2MCwianRpIjoiY2I1MjUyMDEtMTc0NC00NmI5LWIxNDctMWIxNTk5ZmNiZjA0IiwidXNlcm5hbWUiOiJvbmVsb2dpbl9kb21pbmlrLm9oQGN1cGl4LmNvbSJ9.sRenjAZoyvqtx9x7lGwPF5hhmzJVrqNVlDgTptQUkeQ6rFMj-BJTGR9o3tQM8Ih1pUfQ9YuUAIg9q_1NaptqhPX-ru4-_1cvdFw11J5Nz8HiqhS1r6leu_LbqLFKL0RWdqzdF3ZGmEZXRhSXubL75dh_l436Rg3lE8IN_NlXd2DaZm5zNZZulBQg7D3cERAaHuiehEAh_THEE8_Av1Brv2nGKwk0Ypc7gculAVczmv5eefOHXrD-y42J8pag6wbDPw9_pu0DFL-tcb_kguR47G7uXUAA6KjAtnpRwOcB_7uWttIxLmz9I1gZy_Te4JPXWEJYID7S8lVvTdyF9s-0uw",
            "payload": {
                "sub": "d3fa7266-bbbb-414a-af24-6282bb9338f0",
                "cognito:groups": [
                    "ap-northeast-2_6HV13usnG_onelogin"
                ],
                "iss": "https://cognito-idp.ap-northeast-2.amazonaws.com/ap-northeast-2_6HV13usnG",
                "version": 2,
                "client_id": "3c9a3onons9u001crglgbnk32i",
                "origin_jti": "02426b26-c590-46dd-8da9-18ad3bca7666",
                "token_use": "access",
                "scope": "phone openid email",
                "auth_time": 1699499060,
                "exp": 1699502660,
                "iat": 1699499060,
                "jti": "cb525201-1744-46b9-b147-1b1599fcbf04",
                "username": "onelogin_dominik.oh@cupix.com"
            }
        },
        "clockDrift": 0
    },
    "authenticationFlowType": "USER_SRP_AUTH",
    "storage": {
        "domain": "localhost",
        "path": "/",
        "expires": 365,
        "secure": true,
        "sameSite": "strict"
    },
    "keyPrefix": "CognitoIdentityServiceProvider.3c9a3onons9u001crglgbnk32i",
    "userDataKey": "CognitoIdentityServiceProvider.3c9a3onons9u001crglgbnk32i.onelogin_dominik.oh@cupix.com.userData"
}
*/