import { Link, Typography } from "@mui/material";

export const Footer = () => {
  const tosURL = "https://www.cupix.com/tos.html";
  const privacyURL = "https://www.cupix.com/privacy.html";

  return (
    <Typography align="center" sx={{ fontSize: "0.7875rem" }}>
      By signing in, you agree to our <br />
      <Link href={tosURL} underline="none" target="_blank" rel="noopener">
        Terms of Service
      </Link>
      <span className="px-1">and</span>
      <Link href={privacyURL} underline="none" target="_blank" rel="noopener">
        Privacy Policy
      </Link>
    </Typography>
  );
};
