import { CardContentSignIn } from "../components/CardContentSignIn";
import { SignInMethod, SignInMethodConfig, SignInMethodKeys } from "../types/SignInMethod";

import { useAuth } from "../hooks/useAuth";
import { parseQueryString, parseAmplifyConfig } from "../libs/query_parser";
import { useSearchParams, useNavigate} from "react-router-dom";
import { Region } from "../types/Region";
import { cloneSearchParams, isEmailFormat } from "../libs/utils";
import WardenApi from "../api/warden";
import { useEffect, useState } from "react";
import { SIGNIN_ERROR_EMAIL_NOT_FOUND, SIGNIN_ERROR_EMAIL_NOT_FOUND_WITH_REGIONS } from "../assets/strings/en";
import CupixWorksApi from "../api/cupixworks";
import { CupixWorksDomain, CupixWorksDomainPostfix, SignInWithAzure } from "../libs/legacy_utils";
import { CPPageBody } from "../components/CPPageBody";

const defaultQueryString: {[key: string]: string} = {
  team_domain: "",
  region: "",
  config: "",
  context: "",
  skip_team: "false",
}

export default function SignIn() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryString = parseQueryString(searchParams, defaultQueryString);
  const amplifyConfig = parseAmplifyConfig(queryString);
  auth.configureAmplify(amplifyConfig);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [region, setRegion] = useState(amplifyConfig.region);
  const [teamDomain, setTeamDomain] = useState("");
  const [teamInfo, setTeamInfo] = useState<{
    config: SignInMethodConfig[];
    emailDomain: string;
    signinMethods: string;
    emailSignin: boolean;
    useSelfSignup: boolean;
  }>({
    config: [],
    emailDomain: queryString.email_domain || "",
    signinMethods: queryString.signin_methods || "",
    emailSignin: true,
    useSelfSignup: queryString.use_self_signup || false,
  });

  useEffect(() => {
    const cupixWorksApiEndpoint = amplifyConfig[region]?.cupixworksApiEndpoint;
    if (
      !queryString.skip_team &&
      cupixWorksApiEndpoint &&
      queryString.team_domain?.length > 0
    ) {
      new CupixWorksApi(cupixWorksApiEndpoint)
        .findTeamByDomain(queryString.team_domain)
        .then((data) => {
          const signInMethodConfig = data.sign_in_methods
            .filter((method: string) => method !== "email")
            .map((method: string) => {
              const [type, provider] = method.split(":");
              return {
                type: type as SignInMethod,
                identityProvider: provider || type,
                onClick: (identityProvider: string) => {
                  console.log("sign in with", identityProvider);

                  if (identityProvider !== SignInMethodKeys.AAD) {
                    auth.federatedSignIn(identityProvider, amplifyConfig);
                  } else {
                    // TODO: remove this block after AAD is migrated to new API
                    SignInWithAzure(
                      cupixWorksApiEndpoint,
                      queryString.team_domain
                    );
                  }
                },
              } as SignInMethodConfig;
            });

          setTeamDomain(
            `${queryString.team_domain}${CupixWorksDomainPostfix(
              cupixWorksApiEndpoint
            )}`
          );
          setTeamInfo({
            config: signInMethodConfig,
            emailDomain: data.email_domain,
            signinMethods: data.sign_in_methods.join(","),
            emailSignin: data.sign_in_methods.includes("email"),
            useSelfSignup:
              "use_self_signup" in queryString
                ? queryString.use_self_signup
                : data.use_self_signup,
          });
        });
    }
  }, []);

  const configureFailed = () => {
    if (!auth.isConfigured) {
      console.error("Auth is not configured.");
      return true;
    }
    return false;
  }

  const onRegionChanged = (newRegion: Region) => {
    setRegion(newRegion);
  };

  const onClickEmailSignin = async (email: string) => {
    if (configureFailed()) {
      return;
    }

    if (email.length === 0) {
      setErrorMessage("Enter your email");
      return;
    }

    if (!isEmailFormat(email)) {
      setErrorMessage("Enter a valid email");
      return;
    }

    setLoading(true);
    WardenApi.getUserInfo(email, { region: region })
      .then((data) => {
        const params = cloneSearchParams(searchParams);
        params.set("region", region);
        params.set("email", email);

        if (data.status === "CONFIRMED") {
          navigate(`/signin/password?${params.toString()}`);
        } else {
          params.set("migration", "true");
          navigate(`/reset_password/request?${params.toString()}`);
        }
      })
      .catch((reason) => {
        if (amplifyConfig.regions.length > 1) {
          setErrorMessage(SIGNIN_ERROR_EMAIL_NOT_FOUND_WITH_REGIONS);
        } else {
          setErrorMessage(SIGNIN_ERROR_EMAIL_NOT_FOUND);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickTeamDomain = () => {
    const params = cloneSearchParams(searchParams);
    params.delete("skip_team");
    params.set("region", region);

    navigate(`/teams/domain?${params.toString()}`);
  };

  const onClickJoinTeam = () => {
    window.location.href = `${CupixWorksDomain(
      queryString.team_domain,
      region
    )}/signup/join_req?team_domain=${queryString.team_domain}&join=true`;
  }

  if (auth.user?.signInUserSession) {
    navigate(`/redirect?${searchParams.toString()}`);
  }

  if (!queryString.skip_team && !queryString.team_domain) {
    navigate(`/teams/domain?${searchParams.toString()}`);
  }

  return (
    <CPPageBody
      regions={amplifyConfig.regions}
      defaultRegion={amplifyConfig.region}
      showRegion={!queryString.team_domain && amplifyConfig.regions.length > 1}
      onRegionChanged={onRegionChanged}
    >
      <CardContentSignIn
        signInMethodConfig={teamInfo.config}
        enabledEmailSignin={teamInfo.emailSignin}
        onClickEmailSignin={onClickEmailSignin}
        onClickTeamDomain={onClickTeamDomain}
        email={queryString.email}
        teamDomain={teamDomain}
        emailDomain={teamInfo.emailDomain}
        teamSelect={!queryString.skip_team}
        selfSignup={teamInfo.useSelfSignup}
        onClickJoinTeam={onClickJoinTeam}
        errorMessage={errorMessage}
        loading={loading}
      />
    </CPPageBody>
  );
}
