import { useAuth } from "../hooks/useAuth";
import parseQueryString, { parseAmplifyConfig } from "../libs/query_parser";
import { useNavigate, Navigate,  useSearchParams } from "react-router-dom";
import WardenApi from "../api/warden";
import { useState } from "react";
import { cloneSearchParams } from "../libs/utils";
import { CardContentResetPasswordSubmit } from "../components/CardContentResetPasswordSubmit";
import { CPPageBody } from "../components/CPPageBody";

const defaultQueryString: {[key: string]: string} = {
  email: "",
  verfication_code: "",
}

export default function ResetPasswordCode() {
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const queryString = parseQueryString(searchParams, defaultQueryString);
  const amplifyConfig = parseAmplifyConfig(queryString);
  auth.configureAmplify(amplifyConfig);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const configureFailed = () => {
    if (!auth.isConfigured) {
      console.error("Auth is not configured.");
      return true;
    }
    return false;
  }

  if (queryString.verfication_code === "") {
    const params = cloneSearchParams(searchParams);
    params.delete("verfication_code");

    return <Navigate to={`/reset_password/code?${params.toString()}`} replace={true}/>;
  }

  if (queryString.email === "") {
    return <Navigate to={`/?${searchParams.toString()}`} replace={true}/>;
  }

  const onClickResetPassword = async (password: string) => {
    if (configureFailed()) {
      return;
    }

    setLoading(true);
    const result = await auth.forgotPasswordSubmit(queryString.email, queryString.verfication_code, password, amplifyConfig);
    if (result.success) {
      WardenApi.updateUserPasswordUpdatedAt(queryString.email, { region: amplifyConfig.region })
        .then(() => {
          const params = cloneSearchParams(searchParams);
          params.delete("verfication_code");
          params.delete("email");

          navigate(`/?${params.toString()}`);
        })
        .catch((error) => {
          setErrorMessage(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setErrorMessage(result.message);
      console.log(result.message);
      setLoading(false);
    }
  };

  return (
    <CPPageBody
      onBackClicked={() => navigate(-1)}
    >
      <CardContentResetPasswordSubmit
        email={queryString.email}
        teamDomain={queryString.team_domain}
        errorMessage={errorMessage}
        loading={loading}
        onClickResetPassword={onClickResetPassword}
      />
    </CPPageBody>
  );
}
