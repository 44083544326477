import { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, SxProps, Theme } from "@mui/material";
import { Region } from "../types/Region";

interface RegionSelectProps {
  defaultRegion?: Region;
  regions?: Region[];
  onRegionChanged?: (region: Region) => void;
  sx?: SxProps<Theme>;
}

/**
 * Primary UI component for user interaction
 */
export const RegionSelect = ({
  defaultRegion = undefined,
  regions = [],
  onRegionChanged = () => {},
  ...props
}: RegionSelectProps) => {
  const [region, setRegion] = useState(defaultRegion);

  const handleChange = (event: SelectChangeEvent) => {
    setRegion(event.target.value as Region);
    onRegionChanged(event.target.value as Region);
  };

  return (
    <FormControl sx={{ ...props.sx, minWidth: 120 }} size="small">
      <InputLabel id="region-label">Region</InputLabel>
      <Select
        labelId="region-label"
        id="region"
        value={region || ""}
        label="Region"
        onChange={handleChange}
      >
        {regions.map((region) => (
          <MenuItem key={region} value={region}>{region.toUpperCase()}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
