import { Card, CardContent } from "@mui/material";
import React from "react";

import LogoCupixworks from "../assets/images/logo_cupixworks_small.png";

interface CPCardProps {
  children: React.ReactNode;
}

export const CPCard: React.FC<CPCardProps> = ({ 
  children, 
}) => {
  return (
    <Card className="card cp-resp-sign-card">
      <CardContent className="card-content d-flex-column-center">
        <img src={LogoCupixworks} alt="logo_cupixworks" className="mb-4"/>
        {children}
      </CardContent>
    </Card>
  );
};