export const SIGNIN_ERROR_EMAIL_NOT_FOUND = "Couldn't find your Cupix account.";
export const SIGNIN_ERROR_EMAIL_NOT_FOUND_WITH_REGIONS = "Couldn't find your Cupix account. Check if the region is correct and try again."
export const SIGNIN_ERROR_PASSWORD_INCORRECT = "Wrong password. Try again or click Forgot password to reset it.";

export const RESET_PASSWORD_MIGRATION = "We have updated our access management system for improved security and single sign-on support. To continue using our services, please reset your password."
export const RESET_PASSWORD_INSTRUCTION = "We will send a verification code to %s to reset your password.";

export const DIGIT_CODE_TITLE = "Check your email for a code";
export const DIGIT_CODE_HINT = "We've sent a 6-character verification code to %s. Please enter the code below.";
export const DIGIT_CODE_ERROR_EMPTY = "Enter a code.";
export const DIGIT_CODE_ERROR_SHORT = "Wrong number of digits. Try again.";
export const DIGIT_CODE_ERROR_FAILED = "Wrong code. Try again.";

export const MFA_CODE_ERROR_EXPIRED = "Verification code expired. Press Back and try again.";

export const ERROR_CONFIG_CUPIX_API_ENDPOINT = "Unknown error. Please contact Cupix Support.";