import { useState } from "react";

import { useAuth } from "../hooks/useAuth";
import { parseQueryString, parseAmplifyConfig } from "../libs/query_parser";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CardContentSignInMfa } from "../components/CardContentSignInMfa";
import { CPPageBody } from "../components/CPPageBody";
import { DIGIT_CODE_ERROR_EMPTY, DIGIT_CODE_ERROR_SHORT } from "../assets/strings/en";

const defaultQueryString: {[key: string]: string} = {
  email: "",
  region: "",
}

export default function SigninMfa() {
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const queryString = parseQueryString(searchParams, defaultQueryString);
  const amplifyConfig = parseAmplifyConfig(queryString);
  auth.configureAmplify(amplifyConfig);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const configureFailed = () => {
    if (!auth.isConfigured) {
      console.error("Auth is not configured.");
      return true;
    }
    return false;
  }

  const onClickMfaSignin = async (code: string) => {
    if (configureFailed()) {
      return;
    }

    if (code.length === 0) {
      setErrorMessage(DIGIT_CODE_ERROR_EMPTY);
      return;
    }

    if (code.length !== 6) {
      setErrorMessage(DIGIT_CODE_ERROR_SHORT);
      return;
    }

    setLoading(true);
    const result = await auth.sendMfaCode(code, amplifyConfig);
    if (result.success) {
      setErrorMessage("");
    } else {
      setErrorMessage(result.message);
    }
    setLoading(false);
  };

  if (auth.user?.signInUserSession) {
    navigate(`/redirect?${searchParams.toString()}`);
  }

  return (
    <CPPageBody
      onBackClicked={() => {
        navigate(`/?${searchParams.toString()}`);
      }}
    >
      <CardContentSignInMfa
        email={queryString.email}
        onClickMfaSignin={onClickMfaSignin}
        errorMessage={errorMessage}
        loading={loading}
      />
    </CPPageBody>
  );
}
