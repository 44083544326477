import { datadogLogs } from '@datadog/browser-logs';

export const initializeLogger = () => {
  const datadogLogEnabled = process.env.REACT_APP_DATADOG_LOG_ENABLED === 'true';
  // NOTE: version & service name should match with release-version & service in datadog-ci (on pipeline)
  const version = process.env.REACT_APP_DATADOG_VERSION || '0.1.0';

  if (datadogLogEnabled && process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
    datadogLogs.init({
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'cupix-warden',
      forwardErrorsToLogs: true,
      forwardConsoleLogs: 'all',
      sessionSampleRate: 100,
      version: version,
    });
  }
};

export default datadogLogs.logger;