import { format } from "util";
import { DIGIT_CODE_HINT, DIGIT_CODE_TITLE } from "../assets/strings/en";
import { CPCard } from "./CPCard";
import { DigitCodeInput } from "./DigitCodeInput";

interface CardContentResetPasswordCodeProps {
  email: string;
  teamDomain: string;
  onClickSendCode?: (verificationCode: string) => void;
  errorMessage?: string;
  loading?: boolean;
}

export const CardContentResetPasswordCode = ({
  email,
  teamDomain,
  onClickSendCode = () => {},
  ...props
}: CardContentResetPasswordCodeProps ) => {
  return (
    <CPCard>
      <DigitCodeInput
        title={DIGIT_CODE_TITLE}
        hint={format(DIGIT_CODE_HINT, email)}
        labelButton="next"
        onClick={onClickSendCode}
        errorMessage={props.errorMessage}
        loading={props.loading}
      />
    </CPCard>
  );
};
