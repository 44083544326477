import { InputAdornment } from "@mui/material";
import { SingleTextFieldSubmit } from "./SingleTextFieldSubmit";
import CPLink from "./CPLink";
import { CPCard } from "./CPCard";

interface CardContentTeamDomainProps {
  teamDomain?: string;
  teamDomainPostfix?: string;
  onClickFindTeamDomain?: (teamDomain: string) => void;
  onClickFindTeamDomainLink?: () => void;
  onClickContactLink?: () => void;
  errorMessage?: string;
  loading?: boolean;
}

export const CardContentTeamDomain = ({
  teamDomainPostfix,
  onClickFindTeamDomain,
  onClickFindTeamDomainLink,
  onClickContactLink,
  ...props
}: CardContentTeamDomainProps) => {
  return (
    <CPCard>
      <SingleTextFieldSubmit
        title="Sign in to your team"
        hint="Enter your team's Cupix URL"
        label=""
        labelButton="next"
        value={props.teamDomain}
        onClick={onClickFindTeamDomain}
        errorMessage={props.errorMessage}
        loading={props.loading}
        inputProps={{
          endAdornment: <InputAdornment position="start">{teamDomainPostfix}</InputAdornment>,
        }}
      />

      <CPLink
        prefix="Don't know your team URL?"
        label="Find your teams"
        onClick={onClickFindTeamDomainLink}
        sx={{ mt: 2 }}
      />

      <CPLink
        prefix="Don't have a CupixWorks team yet?"
        label="Contact Cupix"
        onClick={onClickContactLink}
        sx={{ mt: 1 }}
      />
    </CPCard>
  );
};
