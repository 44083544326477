import { Box } from "@mui/material";

import { SingleTextFieldSubmit } from "./SingleTextFieldSubmit";
import CPLink from "./CPLink";
import { CPCard } from "./CPCard";

interface CardContentSignInPasswordProps {
  email: string;
  onClickSignin?: (password: string) => void;
  onClickForgotPassword?: () => void;
  errorMessage?: string;
  loading?: boolean;
}

export const CardContentSignInPassword = ({
  email,
  onClickSignin,
  onClickForgotPassword = () => {},
  ...props
}: CardContentSignInPasswordProps) => {
  return (
    <CPCard>
      <SingleTextFieldSubmit
        title="Welcome"
        hint={email}
        label="Enter a password"
        textType="password"
        labelButton="next"
        onClick={onClickSignin}
        errorMessage={props.errorMessage}
        loading={props.loading}
      />

      <Box className="card-row mt-12">
        <CPLink
          label="Forgot password?"
          onClick={onClickForgotPassword} />
      </Box>
    </CPCard>
  );
};
