import { RegionConfig } from "../types/AmplifyConfig";
import { Region, RegionKeys } from "../types/Region";

export const CognitoConfig = ({clientId, region}: {clientId?: string, region?: Region}) => {
  switch (region) {
    case RegionKeys.EU:
      return {
        region: process.env.REACT_APP_AUTH_REGION_EU,
        userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID_EU,
        userPoolWebClientId: clientId || process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID_EU,
      };
    case RegionKeys.AU:
      return {
        region: process.env.REACT_APP_AUTH_REGION_AU,
        userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID_AU,
        userPoolWebClientId: clientId || process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID_AU,
      };
    default:
      return {
        region: process.env.REACT_APP_AUTH_REGION_US,
        userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID_US,
        userPoolWebClientId: clientId || process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID_US,
      };
  }
}

export const OAuthConfig = (region?: Region) => {
  switch (region) {
    case RegionKeys.EU:
      return {
        domain: process.env.REACT_APP_AUTH_USER_POOL_DOMAIN_EU,
      };
    case RegionKeys.AU:
      return {
        domain: process.env.REACT_APP_AUTH_USER_POOL_DOMAIN_AU,
      };
    default:
      return {
        domain: process.env.REACT_APP_AUTH_USER_POOL_DOMAIN_US,
      };
  }
}

export const AwsConfigAuth = (region: Region, config?: RegionConfig) => {
  return {
    ...CognitoConfig({ clientId: config?.clientId, region: region }),
    cookieStorage: {
      domain: process.env.REACT_APP_AUTH_COOKIE_STORAGE_DOMAIN,
      path: "/",
      expires: 365,
      sameSite: "none",
      secure: true,
    },
    authenticationFlowType: "CUSTOM_AUTH",
    oauth: {
      ...OAuthConfig(region),
      redirectSignIn: process.env.REACT_APP_AUTH_REDIRECT_URI,
      redirectSignOut: process.env.REACT_APP_AUTH_REDIRECT_URI,
      redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
      responseType: "code", // code | token
      scope: ["openid", "email", "aws.cognito.signin.user.admin"],
    },
  };
};