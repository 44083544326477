import { useState } from "react";

import { parseQueryString, parseAmplifyConfig } from "../libs/query_parser";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CardContentTeamDomain } from "../components/CardContentTeamDomain";
import CupixWorksApi from "../api/cupixworks";
import { cloneSearchParams } from "../libs/utils";
import { Region } from "../types/Region";
import { CupixWorksDomainPostfix } from "../libs/legacy_utils";
import { CPPageBody } from "../components/CPPageBody";
import { ERROR_CONFIG_CUPIX_API_ENDPOINT } from "../assets/strings/en";

const defaultQueryString: {[key: string]: string} = {
  config: "",
  region: "",
}

export default function TeamDomain() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const queryString = parseQueryString(searchParams, defaultQueryString);
  const amplifyConfig = parseAmplifyConfig(queryString);

  const [teamDomainPostfix, setTeamDomainPostfix] = useState(CupixWorksDomainPostfix(amplifyConfig[amplifyConfig.region]?.cupixworksApiEndpoint));
  const [region, setRegion] = useState(amplifyConfig.region);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onClickFindTeamDomain = async (teamDomain: string) => {
    const cupixWorksApiEndpoint = amplifyConfig[region]?.cupixworksApiEndpoint;
    if (!cupixWorksApiEndpoint) {
      setErrorMessage(ERROR_CONFIG_CUPIX_API_ENDPOINT);
      return;
    }

    setLoading(true);
    new CupixWorksApi(cupixWorksApiEndpoint)
      .findTeamByDomain(teamDomain)
      .then((data) => {
        const params = cloneSearchParams(searchParams);
        params.set("team_domain", data.domain);
        params.set("region", region);

        navigate(`/?${params.toString()}`);
      })
      .catch((error) => {
        setErrorMessage("Team not found.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onRegionChanged = (newRegion: Region) => {
    setRegion(newRegion);
    setTeamDomainPostfix(
      CupixWorksDomainPostfix(amplifyConfig[newRegion]?.cupixworksApiEndpoint)
    );
  };

  const onClickFindTeamDomainLink = async () => {
    navigate(`/teams/find?${searchParams.toString()}`);
  };

  const onClickContactLink = async () => {
    window.open('https://www.cupix.com/contact-sales', '_blank');
  };

  return (
    <CPPageBody
      regions={amplifyConfig.regions}
      defaultRegion={amplifyConfig.region}
      showRegion={amplifyConfig.regions.length > 1}
      onRegionChanged={onRegionChanged}
      onBackClicked={queryString.team_domain ? () => navigate(-1) : undefined}
    >
      <CardContentTeamDomain
        teamDomain={queryString.team_domain}
        teamDomainPostfix={teamDomainPostfix}
        onClickFindTeamDomain={onClickFindTeamDomain}
        onClickFindTeamDomainLink={onClickFindTeamDomainLink}
        onClickContactLink={onClickContactLink}
        errorMessage={errorMessage}
        loading={loading}
      />
    </CPPageBody>
  );
}
