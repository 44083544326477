import { Box, Button, CircularProgress } from '@mui/material';
import { capitalize } from '../libs/utils';

interface CPButtonProps {
  label: string;
  loading?: boolean;
  disabled?: boolean;
}

export const CPButton = ({
  label,
  loading = false,
  disabled = false,
  ...props
}: CPButtonProps) => {
  return (
    <Box className="w-100" sx={{ position: "relative" }}>
      <Button
        className="w-100"
        type="submit"
        variant="contained"
        color="primary"
        disabled={disabled || loading}
      >
        {capitalize(label)}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            color: "primary",
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </Box>
  );
};