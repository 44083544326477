import { Navigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { clear } from "../libs/storage";
import { removeAllCognitoCookies } from "../libs/utils";

export default function SignOut() {
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const redirectTarget = `/?${searchParams.toString()}`;

  auth.signOut();

  // NOTE: Removing cognito cookies to signout out user for all client_id.
  removeAllCognitoCookies();
  clear();

  return <Navigate to={redirectTarget} replace={true}/>;
}
