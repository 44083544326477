import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import { useState } from "react";
import { CPTextField } from "./CPTextField";
import { CPButton } from "./CPButton";

interface SingleTextFieldSubmitProps {
  title: string;
  hint?: string;
  label: string;
  labelButton: string;
  value?: string;
  textType?: string;
  loading?: boolean;
  errorMessage?: string;
  onClick?: (text: string) => void;
  inputProps?: any;
}

export const SingleTextFieldSubmit = ({
  title,
  hint,
  label,
  labelButton,
  loading,
  value = "",
  textType = "text",
  errorMessage = "",
  onClick = () => {},
  ...props
}: SingleTextFieldSubmitProps) => {
  const [text, setText] = useState(value);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onClick(text);
  };

  return (
    <form onSubmit={handleSubmit} className="w-100">
      <Stack className="mb-4">
        <span className="cp-line-height-lg cp-text-h1 font-weight-regular">
          {title}
        </span>
        {hint && <span className="cp-hint">
          {hint}
        </span>}
      </Stack>
      <Box className="mb-4">
        <CPTextField
          id={label}
          label={label}
          value={text}
          type={textType}
          errorMessage={errorMessage}
          onChange={(val) => setText(val)}
          inputProps={props.inputProps}
        />
      </Box>
      <Box>
        <CPButton
          label={labelButton}
          loading={loading}
        />
        
      </Box>
    </form>
  );
};
