import { TextField, Stack } from '@mui/material';
import CPFormHelperText from './CPFormHelperText';
import { capitalize } from '../libs/utils';

interface CPTextFieldProps {
  id: string;
  label: string;
  value: string;
  type?: string;
  autoFocus?: boolean;
  errorMessage?: string;
  onChange?: (val:string) => void;
  inputProps?: any;
}

export const CPTextField = ({
  id,
  label,
  value,
  type = "text",
  autoFocus = true,
  errorMessage= "",
  onChange = () => {},
  ...props
}: CPTextFieldProps) => {
  const hasErrorMessage = errorMessage.length > 0;

  return (
    <Stack spacing={0.5}>
      <TextField
        id={id}
        label={capitalize(label, true)}
        variant="outlined"
        value={value}
        type={type}
        autoFocus={autoFocus}
        onChange={(e) => onChange(e.target.value)}
        aria-describedby={`${id}-helper`}
        color={hasErrorMessage ? 'error': 'primary'}
        error={hasErrorMessage}
        InputProps={props.inputProps}
      />

      <CPFormHelperText
        id={`${id}-helper`}
        message={errorMessage}
        error={hasErrorMessage}
      />
    </Stack>
  );
};