import { Stack } from "@mui/material";
import CPLink from "./CPLink";
import { CPCard } from "./CPCard";

interface CardContentTeamFindSentProps {
  email: string;
  onClickReenterEmail?: () => void;
  onClickResendEmail?: () => void;
  errorMessage?: string;
  loading?: boolean;
}

export const CardContentTeamFindSent = ({
  email = "",
  onClickReenterEmail,
  onClickResendEmail,
  ...props
}: CardContentTeamFindSentProps) => {
  return (
    <CPCard>
      <span className="cp-line-height-lg cp-text-h1 font-weight-regular">
        Check Your Email
      </span>
      <Stack spacing={2}>
        <span className="cp-hint">
          We've emailed you a link at <b>{email}</b>.<br />
          Check the email to confirm teams you can sign in.
        </span>

        <CPLink
          prefix="Wrong email address?"
          label="Re-enter your email address here."
          onClick={onClickReenterEmail} />

        <CPLink
          label="Resend the team confirmation email."
          onClick={onClickResendEmail} />
      </Stack>
    </CPCard>
  );
};
