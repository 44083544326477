import { Amplify, Auth } from "aws-amplify";
import React, { createContext, useContext, useEffect, useState } from "react";
import { AwsConfigAuth } from "../config/auth";
import { AmplifyConfig } from "../types/AmplifyConfig";
import { DIGIT_CODE_ERROR_FAILED, MFA_CODE_ERROR_EXPIRED } from "../assets/strings/en";

interface UseAuth {
    isConfigured: boolean;
    user: any;
    needMFA: boolean;
    configureAmplify: (config: AmplifyConfig) => void;
    signIn: (email: string, password: string, config: AmplifyConfig) => Promise<Result>;
    sendMfaCode: (code: string, config: AmplifyConfig) => Promise<Result>;
    signOut: () => void;
    federatedSignIn: (identityProvider: string, config: AmplifyConfig) => Promise<Result>;
    forgotPassword: (email: string, config: AmplifyConfig) => Promise<Result>;
    forgotPasswordSubmit: (email: string, code: string, password: string, config: AmplifyConfig) => Promise<Result>;
    confirmVerificationCode: (email: string, code: string, config: AmplifyConfig) => Promise<Result>;
}

interface Result {
    success: boolean;
    message: string;
}

type Props = {
    children?: React.ReactNode;
};

const authContext = createContext({} as UseAuth);

export const ProvideAuth: React.FC<Props> = ({ children }) => {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
    return useContext(authContext);
};

const parseErrorMessage = (error: unknown): string => {
    let message = "";
    if (error instanceof Error) {
        message = error.message;
    } else if (typeof error === "string") {
        message = error;
    } else {
        message = JSON.stringify(error);
    }

    if (message.startsWith("Invalid session for the user")) {
        message = MFA_CODE_ERROR_EXPIRED;
    }

    return message;
}

const useProvideAuth = (): UseAuth => {
    const [isConfigured, setIsConfigured] = useState(false);
    const [user, setUser] = useState(null);
    const [needMFA, setNeedMFA] = useState(false);

    useEffect(() => {
        if (!isConfigured) {
            setUser(null);
            setNeedMFA(false);
            return;
        }

        Auth.currentAuthenticatedUser()
            .then((user) => {
                setUser(user);
                setNeedMFA(false);
            })
            .catch(() => {
                setUser(null);
                setNeedMFA(false);
            });
    }, [isConfigured]);

    const configureAmplify = (config: AmplifyConfig) => {
        const authConfig = { 
            Auth: AwsConfigAuth(config.region, config[config.region]),
        };
        
        try {
            Amplify.configure(authConfig);
            setIsConfigured(true);
        } catch (error) {
            setIsConfigured(false);
        }
    }

    const signIn = async (email: string, password: string, config: AmplifyConfig) => {
        try {
            configureAmplify(config);
            const user = await Auth.signIn(email.toLowerCase(), password);
            setNeedMFA(user?.challengeName === "CUSTOM_CHALLENGE")
            setUser(user);
            return { success: true, message: "" };
        } catch (error) {
            console.error(`[signIn(${email})]`, parseErrorMessage(error), JSON.stringify(error));
            return {
                success: false,
                message: parseErrorMessage(error),
            };
        }
    };

    const sendMfaCode = async (code: string, config: AmplifyConfig) => {
        try {
            configureAmplify(config);
            const output = await Auth.sendCustomChallengeAnswer(user, code);
            if (!output?.signInUserSession) {
                return {
                    success: false,
                    message: DIGIT_CODE_ERROR_FAILED,
                };
            }

            setNeedMFA(false);
            setUser(output);
            return { success: true, message: "" };
        } catch (error) {
            console.error("[sendMfaCode]", parseErrorMessage(error), JSON.stringify(error));
            return {
                success: false,
                message: parseErrorMessage(error),
            };
        }
    };

    const signOut = async () => {
        try {
            await Auth.signOut();
            setNeedMFA(false);
            setUser(null);
            return { success: true, message: "" };
        } catch (error) {
            console.error("[signOut]", parseErrorMessage(error), JSON.stringify(error));
            return {
                success: false,
                message: parseErrorMessage(error),
            };
        }
    };

    const federatedSignIn = async (identityProvider: string, config: AmplifyConfig) => {
        try {
            configureAmplify(config);
            await Auth.federatedSignIn({customProvider: identityProvider}); // NOTE: it automatically calls Auth.currentAuthenticatedUser() and updates the state
            return { success: true, message: "Forwared to the provider" }
        } catch (error) {
            console.error(`[federatedSignIn(${identityProvider})]`, parseErrorMessage(error), JSON.stringify(error));
            return {
                success: false,
                message: parseErrorMessage(error),
            }
        }
    };

    const forgotPassword = async (email: string, config: AmplifyConfig) => {
        try {
            configureAmplify(config);
            await Auth.forgotPassword(email.toLowerCase());
            return { success: true, message: "Forgot password email sent"};
        } catch (error) {
            console.error(`[forgotPassword(${email})]`, parseErrorMessage(error), JSON.stringify(error));
            return {
                success: false,
                message: parseErrorMessage(error),
            }
        }
    };

    const forgotPasswordSubmit = async (email: string, code: string, password: string, config: AmplifyConfig) => {
        try {
            configureAmplify(config);
            await Auth.forgotPasswordSubmit(email.toLowerCase(), code, password);
            return { success: true, message: "Password has reset"};
        } catch (error) {
            console.error(`[forgotPasswordSubmit(${email})]`, parseErrorMessage(error), JSON.stringify(error));
            return {
                success: false,
                message: parseErrorMessage(error),
            }
        }
    };

    const confirmVerificationCode = async (email: string, code: string, config: AmplifyConfig) => {
        try {
            configureAmplify(config);
            // NOTE: there is no way to check if the code is valid or not, so call forgotPasswordSubmit() with dummy password
            await Auth.forgotPasswordSubmit(email.toLowerCase(), code, "dummy_password");
            return { success: true, message: "Password has reset"};
        } catch (error) {
            if ((error as any)?.code === "InvalidPasswordException") {
                return { success: true, message: ""};
            }

            console.error(`[confirmVerificationCode(${email})]`, parseErrorMessage(error), JSON.stringify(error));
            return {
                success: false,
                message: parseErrorMessage(error),
            }
        }
    };

    return {
        isConfigured,
        user,
        needMFA,
        configureAmplify,
        signIn,
        sendMfaCode,
        signOut,
        federatedSignIn,
        forgotPassword,
        forgotPasswordSubmit,
        confirmVerificationCode,
    };
};
