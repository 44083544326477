import { Buffer } from "buffer";
import { get, set, storagableKeys } from "./storage";
import { CognitoConfig } from "../config/auth";
import { AmplifyConfig, URLConfig } from "../types/AmplifyConfig";
import { Region } from "../types/Region";

const parseString = (param: string | null) => {
  if (!param) return "";

  switch (param) {
    case "true":
      return true;
    case "false":
      return false;
    case "null":
      return undefined;
    case "undefined":
      return undefined;
    default:
      return decodeURI(param);
  }
};

export const parseQueryString = (searchParams: URLSearchParams, defaultQueryString: any) => {
  const params = {...defaultQueryString};
  
  // parse string into object
  Object.keys(params).forEach(key => {
    params[key] = parseString(params[key]);
  });

  for (const key of searchParams.keys()) {
    if (searchParams.get(key)) {
      params[key] = parseString(searchParams.get(key));
    } else {
      params[key] = defaultQueryString[key] || "";
      params[key] = decodeURI(params[key]);
    }
  }

  storagableKeys.forEach(key => {
    if (!params[key] || params[key] === "") {
      params[key] = get(key);
    } else {
      set(key, params[key]);
    }
  });

  if (params.client_id === null || params.client_id === "") {
    params.client_id = CognitoConfig({clientId: params.client_id}).userPoolWebClientId
  }

  return params;
}

export const parseAmplifyConfig = (queryString: any): AmplifyConfig => {
  const config = queryString.config;

  try {
    const decoded = Buffer.from(config, 'base64').toString('utf-8');
    const decodedConfig = JSON.parse(decoded) as URLConfig[];
    const amplifyConfig = {
      region: decodedConfig[0].region_display.toLowerCase(),
      regions: [],
    } as AmplifyConfig;

    decodedConfig.forEach((config: URLConfig) => {
      const region = config.region_display.toLowerCase() as Region;
      amplifyConfig[region] = {
        clientId: config.client_id,
        redirectUri: config.redirect_uri,
        cupixworksApiEndpoint: config.cupixworks_api_endpoint,
      };

      amplifyConfig.regions.push(region);
      if (config.selected) {
        amplifyConfig.region = region;
      }
    });

    if (
      queryString.region &&
      queryString.region.length > 0 &&
      amplifyConfig.regions.includes(queryString.region)
    ) {
      amplifyConfig.region = queryString.region;
    }

    set("redirect_uri", amplifyConfig[amplifyConfig.region]?.redirectUri || "");
    set("region", amplifyConfig.region || "");
    return amplifyConfig;
  } catch (e) {
    console.error('Failed to decode base64 clients config: ', JSON.stringify(e));
    console.error('config: ', config);
    return {
      region: "" as Region,
      regions: [],
    }
  }
}

export default parseQueryString;