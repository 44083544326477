const storageKeyPrefix = "accounts.cupix.works:";
export const storagableKeys = ["region", "team_domain", "context", "config"];

export const get = (key: string) => {
  const value = sessionStorage.getItem(`${storageKeyPrefix}${key}`);
  if (value === "undefined") return undefined;
  if (value === "null") return undefined;
  return value;
}

export const set = (key: string, value: string) => {
  sessionStorage.setItem(`${storageKeyPrefix}${key}`, value);
}

export const clear = () => {
  sessionStorage.clear();
}