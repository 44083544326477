import { useAuth } from "../hooks/useAuth";
import { parseQueryString, parseAmplifyConfig } from "../libs/query_parser";
import { useSearchParams, useNavigate} from "react-router-dom";
import { useEffect, useState } from "react";
import { CPPageBody } from "../components/CPPageBody";
import { CardContentError } from "../components/CardContentError";

const defaultQueryString: {[key: string]: string} = {
  team_domain: "",
  region: "",
  config: "",
  identity_provider: "",
}

export default function FederatedSignIn() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryString = parseQueryString(searchParams, defaultQueryString);
  const amplifyConfig = parseAmplifyConfig(queryString);
  auth.configureAmplify(amplifyConfig);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const identityProvider = queryString.identity_provider;
    console.log("[federated] sign in with", identityProvider);
    auth.federatedSignIn(identityProvider, amplifyConfig)
    .catch((error) => {
      setErrorMessage(error.message);
    });
  }, []);

  if (auth.user?.signInUserSession) {
    navigate(`/redirect?${searchParams.toString()}`);
  }

  if (errorMessage) {
    return (
      <CPPageBody>
        <CardContentError
          message={errorMessage}
        />
      </CPPageBody>
    );
  }

  // TODO: show waiting page
  return null;
}
