import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { ProvideAuth } from "./hooks/useAuth";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";

import "./index.scss";
import Signin from "./pages/Signin";
import ResetPasswordRequest from "./pages/ResetPasswordRequest";
import Signout from "./pages/Signout";
import SigninPassword from "./pages/SigninPassword";
import TeamDomain from "./pages/TeamDomain";
import SigninMfa from "./pages/SigninMfa";
import ResetPasswordCode from "./pages/ResetPasswordCode";
import ResetPasswordSubmit from "./pages/ResetPasswordSubmit";
import TeamFind from "./pages/TeamFind";
import TeamFindSent from "./pages/TeamFindSent";
import RedirectToRedirectUri from "./pages/RedirectToRedirectUri";
import { initializeLogger } from "./libs/logger";
import FederatedSignIn from "./pages/FederatedSignin";
initializeLogger();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Signin />
    ),
  },
  {
    path: "/signin/federated",
    element: (
      <FederatedSignIn />
    ),
  },
  {
    path: "/signin/password",
    element: (
      <SigninPassword />
    ),
  },
  {
    path: "/signin/mfa",
    element: (
      <SigninMfa />
    ),
  },
  {
    path: "/reset_password/request",
    element: (
      <ResetPasswordRequest />
    ),
  },
  {
    path: "/reset_password/code",
    element: (
      <ResetPasswordCode />
    ),
  },
  {
    path: "/reset_password/submit",
    element: (
      <ResetPasswordSubmit />
    ),
  },
  {
    path: "/signout",
    element: (
      <Signout />
    ),
  },
  {
    path: "/redirect",
    element: (
      <RedirectToRedirectUri />
    ),
  },
  {
    path: "/teams/domain",
    element: (
      <TeamDomain />
    )
  },
  {
    path: "/teams/find",
    element: (
      <TeamFind />
    )
  },
  {
    path: "/teams/find/sent",
    element: (
      <TeamFindSent />
    )
  },
]);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ProvideAuth>
        <RouterProvider router={router} />
      </ProvideAuth>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
