import { useState } from "react";

import { parseQueryString, parseAmplifyConfig } from "../libs/query_parser";
import { useNavigate, useSearchParams } from "react-router-dom";
import CupixWorksApi from "../api/cupixworks";
import { cloneSearchParams, isEmailFormat } from "../libs/utils";
import { CardContentTeamFind } from "../components/CardContentTeamFind";
import { CPPageBody } from "../components/CPPageBody";
import { ERROR_CONFIG_CUPIX_API_ENDPOINT } from "../assets/strings/en";

const defaultQueryString: {[key: string]: string} = {
  config: "",
  region: "",
}

export default function TeamFind() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const queryString = parseQueryString(searchParams, defaultQueryString);
  const amplifyConfig = parseAmplifyConfig(queryString);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onClickFindTeam = (email: string) => {
    const cupixWorksApiEndpoint = amplifyConfig[amplifyConfig.region]?.cupixworksApiEndpoint;
    if (!cupixWorksApiEndpoint) {
      setErrorMessage(ERROR_CONFIG_CUPIX_API_ENDPOINT);
      return;
    }

    if (!isEmailFormat(email)) {
      setErrorMessage("Enter a valid email");
      return;
    }

    setLoading(true);
    new CupixWorksApi(cupixWorksApiEndpoint).requestMyTeam(email)
      .then(() => {
        const params = cloneSearchParams(searchParams);
        params.set("email", email);

        navigate(`/teams/find/sent?${params.toString()}`);
      })
      .catch((error) => {
        setErrorMessage(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CPPageBody
      onBackClicked={() => navigate(-1)}
    >
      <CardContentTeamFind
        email={queryString.email}
        onClickFindTeam={onClickFindTeam}
        errorMessage={errorMessage}
        loading={loading}
      />
    </CPPageBody>
  );
}
