import { Alert, AlertColor } from '@mui/material';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

interface CPAlertProps {
  message?: string;
  severity?: AlertColor;
}

export default function CPAlert({
  message = "",
  severity = "error"
}: CPAlertProps) {
  if (message.length === 0) {
    return null;
  }

  return (
    <Alert 
      severity={severity}
      iconMapping={{
        error: <ReportProblemOutlinedIcon fontSize="inherit" />,
      }}
    >
        {message}
    </Alert>
  );
};