import { Link, Stack, SxProps, Theme, Typography } from '@mui/material';

interface CPLinkProps {
  label: string;
  prefix?: string;
  onClick?: () => void;
  spacing?: number;
  sx?: SxProps<Theme>;
}

export function CPLink({
  label = "",
  prefix = "",
  onClick = () => {},
  spacing = 1,
  sx = {},
}: CPLinkProps) {
  return (
    <Stack 
      direction="row" 
      spacing={spacing}
      alignItems="center"
      justifyContent="center"
      sx={sx}
    >

      <Typography sx={{ fontSize: '14px' }}>
        {prefix + " "}
        <Link
          component="button"
          underline="none"
          onClick={onClick}>
        {label}
        </Link>
      </Typography>

    </Stack>
  );
};

export default CPLink;