import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { clear } from "../libs/storage";
import { removeAllCognitoCookies } from "../libs/utils";
import parseQueryString, { parseAmplifyConfig } from "../libs/query_parser";
import { composeUri } from "../libs/query_composer";
import { useEffect, useState } from "react";
import CupixWorksApi from "../api/cupixworks";
import { CardContentError } from "../components/CardContentError";
import { CPPageBody } from "../components/CPPageBody";

const defaultQueryString: {[key: string]: string} = {
  team_domain: "",
  region: "",
  config: "",
  context: "",
  skip_team: "false",
}

export default function RedirectToRedirectUri() {
  const auth = useAuth();
  const [searchParams] = useSearchParams();  
  const navigate = useNavigate();
  const queryString = parseQueryString(searchParams, defaultQueryString);
  const amplifyConfig = parseAmplifyConfig(queryString);
  auth.configureAmplify(amplifyConfig);

  const [showError, setShowError] = useState(false);

  const clearAll = () => {
    auth.signOut();
    removeAllCognitoCookies();
    clear();
  }

  const redirectTo = () => {
    const redirectUri = amplifyConfig[amplifyConfig.region]?.redirectUri;
    if (!redirectUri) {
      console.error("Redirect URI is not configured.");
      return;
    }

    const redirectTarget = composeUri(
      redirectUri,
      amplifyConfig.region,
      queryString.team_domain,
      auth.user.signInUserSession
    );

    clearAll();
    window.location.href = redirectTarget;
  }

  useEffect(() => {
    if (!auth.user) {
      return;
    }

    const cupixWorksApiEndpoint =
      amplifyConfig[amplifyConfig.region]?.cupixworksApiEndpoint;
    if (
      !queryString.skip_team &&
      cupixWorksApiEndpoint &&
      queryString.team_domain?.length > 0
    ) {
      const accessToken = auth.user.signInUserSession.accessToken.jwtToken;
      new CupixWorksApi(cupixWorksApiEndpoint)
        .getSession(queryString.team_domain, accessToken)
        .then((data) => {
          if (data.user.attributes.state === "active") {
            redirectTo();
          } else {
            console.error("user is not active: ", data.user.attributes);
            setShowError(true);
          }
        })
        .catch((error) => {
          setShowError(true);
        });
    } else {
      redirectTo();
    }
  }, [auth.user]);

  if (showError) {
    return (
      <CPPageBody
        onBackClicked={() => {
          clearAll();
          navigate(`/?${searchParams.toString()}`);
        }}
      >
        <CardContentError
          message="You do not have access to this team. Please contact your team admin for assistance."
        />
      </CPPageBody>
    );
  }

  // TODO: show waiting page
  return null;
}
