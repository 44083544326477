import { CardContentResetPasswordRequest } from "../components/CardContentResetPasswordRequest";
import { useAuth } from "../hooks/useAuth";
import { useNavigate, useSearchParams } from "react-router-dom";
import parseQueryString, { parseAmplifyConfig } from "../libs/query_parser";
import { cloneSearchParams } from "../libs/utils";
import { useState } from "react";
import { CPPageBody } from "../components/CPPageBody";

const defaultQueryString: {[key: string]: string} = {
  config: "",
  email: "",
  migration: "",
}

export default function ResetPasswordRequest() {
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const queryString = parseQueryString(searchParams, defaultQueryString);
  const amplifyConfig = parseAmplifyConfig(queryString);
  auth.configureAmplify(amplifyConfig);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const configureFailed = () => {
    if (!auth.isConfigured) {
      console.error("Auth is not configured.");
      return true;
    }
    return false;
  }

  const onClickResetPasswordRequest = async () => {
    if (configureFailed()) {
      return;
    }

    setLoading(true);
    const result = await auth.forgotPassword(queryString.email, amplifyConfig);
    if (result.success) {
      const params = cloneSearchParams(searchParams);
      params.set("email", queryString.email);
      navigate(`/reset_password/code?${params.toString()}`)
    } else {
      setErrorMessage(result.message);
    }
    setLoading(false);
  };

  return (
    <CPPageBody
      onBackClicked={() => {
        if (queryString.migration) {
          const params = cloneSearchParams(searchParams);
          params.set("email", queryString.email);
          params.delete("migration");

          navigate(`/?${params.toString()}`);
        } else {
          navigate(-1);
        }
      }}
    >
      <CardContentResetPasswordRequest
        migration={queryString.migration}
        onClickResetPasswordRequest={onClickResetPasswordRequest}
        loading={loading}
        errorMessage={errorMessage}
      />
    </CPPageBody>
  );
}
