import { TextField, Stack, styled} from '@mui/material';
import { useRef, useState } from 'react';
import CPFormHelperText from './CPFormHelperText';

interface CPMfaTextFieldProps {
  id: string;
  count: number;
  errorMessage?: string;
  onChange?: (val:string) => void;
}

const NumberInput = styled(TextField)(({ theme }) => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
}));

export const CPMfaTextField = ({
  id,
  count,
  errorMessage= "",
  onChange = () => {},
  ...props
}: CPMfaTextFieldProps) => {
  const hasErrorMessage = errorMessage.length > 0;
  const refs = useRef<(HTMLInputElement)[]>([]);
  const [values, setValues] = useState<string[]>(Array(count).fill(''));

  const handleOnChange = (n: string, index: number) => {
    const newValues = [...values];
    newValues[index] = n;
    setValues(newValues);
    onChange(newValues.join(''));

    if (refs.current[index-1] && n.length === 0) {
      refs.current[index-1].focus();
    } else if (refs.current[index+1] && n.length > 0) {
      refs.current[index+1].focus();
    }
  };

  const handleOnPaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').trim();
    const newValues = [...values];
    for (let i = 0; i < newValues.length; i++) {
      newValues[i] = pastedData[i];
    }
    setValues(newValues);
    onChange(newValues.join(''));
  };

  return (
    <Stack spacing={0.5}>
      <Stack 
        direction="row" 
        spacing={1}
      >
        {Array.from({ length: count}, (_, i) => (
          <NumberInput
            id={`${id}-${i}`}
            key={`${id}-${i}`}
            value={values[i]}
            label=""
            variant="outlined"
            type="number"
            autoFocus={i === 0}
            onChange={(e) => handleOnChange(e.target.value, i)}
            onPaste={(e) => handleOnPaste(e)}
            aria-describedby={`${id}-helper`}
            color={hasErrorMessage ? 'error': 'primary'}
            error={hasErrorMessage}
            inputProps={{ maxLength: 1, style: { textAlign: 'center', paddingLeft: '0', paddingRight: '0' } }}
            inputRef={ref => refs.current[i] = ref}
          />
        ))}
      </Stack>

    <CPFormHelperText
      id={`${id}-helper`}
      message={errorMessage}
      error={hasErrorMessage}
    />
    </Stack>
  );
};