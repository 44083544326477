import { Stack } from "@mui/material";
import { RESET_PASSWORD_INSTRUCTION, RESET_PASSWORD_MIGRATION } from "../assets/strings/en";
import { format } from "util";
import { CPButton } from "./CPButton";
import CPAlert from "./CPAlert";
import { CPCard } from "./CPCard";

interface CardContentResetPasswordRequestProps {
  email?: string;
  migration?: boolean;
  onClickResetPasswordRequest?: () => void;
  loading?: boolean;
  errorMessage?: string;
}

export const CardContentResetPasswordRequest = ({
  email = "",
  migration = false,
  onClickResetPasswordRequest,
  ...props
}: CardContentResetPasswordRequestProps) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (onClickResetPasswordRequest) {
      onClickResetPasswordRequest();
    }
  }

  const hint = migration ? RESET_PASSWORD_MIGRATION : format(RESET_PASSWORD_INSTRUCTION, email);

  return (
    <CPCard>
      <span className="cp-line-height-lg cp-text-h1 font-weight-regular">
        {migration ? "Reset Password Required" : "Forgot your password?"}
      </span>

      <Stack spacing={3} className="w-100">
        <span className="cp-hint">
          {hint}
        </span>
        
        <CPAlert message={props.errorMessage} />

        <form onSubmit={handleSubmit} className="w-100">
          <CPButton
            label="reset password"
            loading={props.loading}
          />
        </form>
      </Stack>
    </CPCard>
  );
};
