import ky from 'ky';

interface CommonResponse {
  message?: string;
  error?: string;
}

interface UserInfoResponse {
  enabled: boolean;
  mfa: string;
  status: string;
}

interface RequestOption {
  region?: string;
}

class WardenApi {
  private static BASE_URL = process.env.REACT_APP_WARDEN_API_URL;

  private static instance(option: RequestOption) {
    return ky.create({
      headers: {
        'x-cupix-region': option.region,
      },
      timeout: 60000, // 60 seconds
      hooks: {
        beforeError: [
          error => {
            error.response.text().then((errorBody: any) => console.error(JSON.stringify(errorBody)));
            return error;
          }
        ],
        beforeRetry: [
          ({request, options, error, retryCount}) => {
            console.error("trying to retry request: ", {
              url: request.url,
              retryCount: retryCount,
              message: error.message
            });
          }
        ]
      }
    });
  }

  public static getUserInfo(email: string, option: RequestOption) {
    const encodedEmail = encodeURIComponent(email.toLowerCase());
    return this.instance(option).get(`${this.BASE_URL}/users?email=${encodedEmail}`).json<UserInfoResponse>();
  }

  public static updateUserPasswordUpdatedAt(email: string, option: RequestOption) {
    return this.instance(option).post(`${this.BASE_URL}/users`, {
      json: {
        email: email.toLowerCase(),
        attributes: [{
          Name: 'custom:password_updated_at',
          Value: new Date().toString(),
        }]
      },
    }).json<CommonResponse>();
  }
}

export default WardenApi;