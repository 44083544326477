import { useState } from "react";

import { parseQueryString, parseAmplifyConfig } from "../libs/query_parser";
import { useNavigate, useSearchParams } from "react-router-dom";
import CupixWorksApi from "../api/cupixworks";
import { cloneSearchParams, isEmailFormat } from "../libs/utils";
import { CardContentTeamFindSent } from "../components/CardContentTeamFindSent";
import { CPPageBody } from "../components/CPPageBody";
import { ERROR_CONFIG_CUPIX_API_ENDPOINT } from "../assets/strings/en";

const defaultQueryString: {[key: string]: string} = {
  config: "",
  region: "",
  email: "",
}

export default function TeamFindSent() {
  const [searchParams] = useSearchParams();
  const queryString = parseQueryString(searchParams, defaultQueryString);
  const navigate = useNavigate();
  const amplifyConfig = parseAmplifyConfig(queryString);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onClickReenterEmail = () => {
    const params = cloneSearchParams(searchParams);
    params.delete("email");

    navigate(`/teams/find?${params.toString()}`);
  };

  const onClickResendEmail = () => {
    const cupixWorksApiEndpoint = amplifyConfig[amplifyConfig.region]?.cupixworksApiEndpoint;
    if (!cupixWorksApiEndpoint) {
      setErrorMessage(ERROR_CONFIG_CUPIX_API_ENDPOINT);
      return;
    }

    if (!isEmailFormat(queryString.email)) {
      setErrorMessage("Enter a valid email");
      return;
    }

    setLoading(true);
    new CupixWorksApi(cupixWorksApiEndpoint).requestMyTeam(queryString.email)
      .then(() => {
        // do nothing
      })
      .catch((error) => {
        setErrorMessage(error);
      })
      .finally(() => {
        setLoading(false);
    });
  };

  return (
    <CPPageBody
      onBackClicked={() => {
        navigate(`/teams/domain?${searchParams.toString()}`);
      }}
    >
      <CardContentTeamFindSent
        email={queryString.email}
        onClickReenterEmail={onClickReenterEmail}
        onClickResendEmail={onClickResendEmail}
        errorMessage={errorMessage}
        loading={loading}
      />
    </CPPageBody>
  );
}
