export const SignInMethodKeys = {
  MEI: 'microsoft_entra_id', // Azure Active Directory
  AAD: 'azure',              // Azure Active Directory - legacy on tesla
  NTT: 'ntt_idf',            // NTT Docomo
  AMZ: 'amazon',             // Amazon
} as const;

export type SignInMethod = typeof SignInMethodKeys[keyof typeof SignInMethodKeys];
export type SignInMethodConfig = {
  type: SignInMethod, 
  identityProvider: string,
  onClick: (identityProvider: string) => void,
};

