import { useState } from "react";

import { useAuth } from "../hooks/useAuth";
import { parseQueryString, parseAmplifyConfig } from "../libs/query_parser";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CardContentSignInPassword } from "../components/CardContentSignInPassword";
import { cloneSearchParams } from "../libs/utils";
import { SIGNIN_ERROR_PASSWORD_INCORRECT } from "../assets/strings/en";
import { CPPageBody } from "../components/CPPageBody";

const defaultQueryString: {[key: string]: string} = {
  email: "",
  region: "",
}

export default function SigninPassword() {
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const queryString = parseQueryString(searchParams, defaultQueryString);
  const amplifyConfig = parseAmplifyConfig(queryString);
  auth.configureAmplify(amplifyConfig);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const configureFailed = () => {
    if (!auth.isConfigured) {
      console.error("Auth is not configured.");
      return true;
    }
    return false;
  }

  const onClickEmailSignin = async (password: string) => {
    if (configureFailed()) {
      return;
    }

    if (password.length === 0) {
      setErrorMessage("Enter a password.");
      return;
    }

    setLoading(true);
    const result = await auth.signIn(queryString.email, password, amplifyConfig);
    if (result.success) {
      setErrorMessage("");
    } else {
      setErrorMessage(SIGNIN_ERROR_PASSWORD_INCORRECT);
    }
    setLoading(false);
  };

  const onClickForgotPassword = () => {
    const params = cloneSearchParams(searchParams);
    params.set("email", queryString.email);

    navigate(`/reset_password/request/?${params.toString()}`);
  }

  if (auth.user?.signInUserSession) {
    navigate(`/redirect?${searchParams.toString()}`);
  }

  if (auth.user && auth.needMFA) {
    const params = cloneSearchParams(searchParams);
    params.set("email", queryString.email);

    navigate(`/signin/mfa?${params.toString()}`);
  }

  return (
    <CPPageBody
      onBackClicked={() => {
        navigate(`/?${searchParams.toString()}`);
      }}
    >
      <CardContentSignInPassword
        email={queryString.email}
        onClickSignin={onClickEmailSignin}
        onClickForgotPassword={onClickForgotPassword}
        errorMessage={errorMessage}
        loading={loading}
      />
    </CPPageBody>
  );
}
