import { DigitCodeInput } from "./DigitCodeInput";
import { CPCard } from "./CPCard";
import { DIGIT_CODE_HINT, DIGIT_CODE_TITLE } from "../assets/strings/en";
import { format } from "util";

interface CardContentSignInMfaProps {
  email: string;
  onClickMfaSignin?: (code: string) => void;
  errorMessage?: string;
  loading?: boolean;
}

export const CardContentSignInMfa = ({
  email,
  onClickMfaSignin = () => {},
  ...props
}: CardContentSignInMfaProps) => {
  return (
    <CPCard>
      <DigitCodeInput
        title={DIGIT_CODE_TITLE}
        hint={format(DIGIT_CODE_HINT, email)}
        labelButton="next"
        onClick={onClickMfaSignin}
        errorMessage={props.errorMessage}
        loading={props.loading}
      />

    </CPCard>
  );
};
