import { Box, Stack } from "@mui/material";
import { CPButton } from "./CPButton";
import { CPTextField } from "./CPTextField";
import { useState } from "react";
import CPFormHelperText from "./CPFormHelperText";
import { CPCard } from "./CPCard";

interface CardContentResetPasswordSubmitProps {
  email: string;
  teamDomain: string;
  onClickResetPassword?: (password: string) => void;
  errorMessage?: string;
  loading?: boolean;
}

export const CardContentResetPasswordSubmit = ({
  email,
  teamDomain,
  onClickResetPassword = () => {},
  ...props
}: CardContentResetPasswordSubmitProps ) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const matchPassword = password === confirmPassword;

  const passwordValidations = [
    {
      name: "length",
      message: "Minimum 8 characters",
      isValid: password.length >= 8,
    },
    {
      name: "uppercase",
      message: "At least 1 uppercase letter",
      isValid: (/[A-Z]/.test(password)),
    },
    {
      name: "lowercase",
      message: "At least 1 lowercase letter",
      isValid: (/[a-z]/.test(password)),
    },
    {
      name: "number",
      message: "At least 1 number",
      isValid: (/\d/.test(password)),
    },
    {
      name: "special",
      message: "At least 1 special character",
      // eslint-disable-next-line no-useless-escape
      isValid: (/[!@#$%&*)(+=._\-\^\]\*]/.test(password)),
    },
  ];

  const validPassword = () => {
    return matchPassword && passwordValidations.every((validation) => validation.isValid);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validPassword()) {
      onClickResetPassword(password);
    }
  };

  return (
    <CPCard>
      <Stack>
        <span className="cp-line-height-lg cp-text-h1 font-weight-regular">
          Password Reset
        </span>
        <span className="cp-hint">
          Enter your new password for your Cupix account.
        </span>
      </Stack>
      <form onSubmit={handleSubmit} className="w-100">
        <Box className="card-row w-100">
          <Stack spacing={2}>
            <Box>
              <CPTextField
                id="password"
                label="password"
                value={password}
                type="password"
                onChange={(val) => setPassword(val)}
              />

              {passwordValidations.map((validation) => (
                <CPFormHelperText
                  key={validation.name}
                  id={`${validation.name}-helper`}
                  message={validation.message} error={!validation.isValid}
                />
              ))}
            </Box>

            <CPTextField
              id="confirm-password"
              label="Confirm password"
              value={confirmPassword}
              type="password"
              autoFocus={false}
              onChange={(val) => setConfirmPassword(val)}
              errorMessage={matchPassword? props.errorMessage : "Passwords do not match."}
            />
          </Stack>
        </Box>
        <Box className="card-row w-100">
          <CPButton
            label="Change Password"
            loading={props.loading}
            disabled={!validPassword()}
          />
        </Box>
      </form>
    </CPCard>
  );
};
