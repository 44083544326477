import { Stack, Typography } from "@mui/material";

import ImageGeneralError from "../assets/images/general-error@2x.png";

interface CardContentErrorProps {
  message: string;
}

export const CardContentError = ({
  message,
  ...props
}: CardContentErrorProps) => {
  return (
    <Stack 
      spacing={2}
      className="cp-resp-sign-card d-flex-column-center">
      <img src={ImageGeneralError} alt="image_general_error" />

      <Typography align="center" className="cp-hint">
        {message}
      </Typography>
    </Stack>
  );
};
