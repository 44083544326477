export const SignInWithAzure = (cupixWorksApiEndpoint: string, teamDomain: string) => {
  const form = document.createElement("form");
  form.method = "POST";
  form.action = `${cupixWorksApiEndpoint}/auth/azure_activedirectory_v2`;

  const input = document.createElement("input");
  input.type = "hidden";
  input.name = "team_domain";
  input.value = teamDomain;

  form.appendChild(input)
  document.body.appendChild(form);

  form.submit();
};

export const CupixWorksDomain = (teamDomain = "app", region = "us") => {
  const postfixRegion = region.toLowerCase() === "us" ? "" : `-${region.toLowerCase()}`;
  return `https://${teamDomain}.cupix${postfixRegion}.works`;
}

export const CupixWorksDomainPostfix = (cupixworksApiEndpoint: string | undefined) => {
  try {
    if (!cupixworksApiEndpoint) {
      throw new Error("cupixworksApiEndpoint is undefined.");
    }

    const splited = cupixworksApiEndpoint.split(".");
    if (splited.length < 3) {
      throw new Error("cupixworksApiEndpoint is invalid.");
    }

    return `.${splited[splited.length - 2]}.${splited[splited.length - 1]}`;
  } catch (e) {
    console.error("Failed to parse cupixworksApiEndpoint to get domain postfix: ", e);
    return ".cupix.works";
  }

}