import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import { useState } from "react";
import { CPMfaTextField } from "./CPMfaTextField";
import { CPButton } from "./CPButton";
import { emailRegex } from "../libs/utils";

interface DigitCodeInputProps {
  title: string;
  hint?: string;
  labelButton: string;
  errorMessage?: string;
  loading?: boolean;
  onClick?: (text: string) => void;
  inputProps?: any;
}

export const DigitCodeInput = ({
  title,
  hint,
  labelButton,
  errorMessage = "",
  onClick = () => {},
  ...props
}: DigitCodeInputProps) => {
  const [text, setText] = useState("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onClick(text);
  };

  const hintParts = hint?.split(emailRegex) || [];
  const email = hint?.match(emailRegex);

  return (
    <form onSubmit={handleSubmit}>
      <Stack className="mb-4">
        <span className="cp-line-height-lg cp-text-h1 font-weight-regular">
          {title}
        </span>
        {hint && <span className="cp-hint">
          {hintParts[0]}
          {email && <strong>{email[0]}</strong>}
          {hintParts[1]}
        </span>}
      </Stack>
      <Box className="mb-4">
        <CPMfaTextField
          id="mfa-code"
          count={6}
          errorMessage={errorMessage}
          onChange={(val) => setText(val)}
        />
      </Box>
      <Box>
        <CPButton
          label={labelButton}
          loading={props.loading}
        />
      </Box>
    </form>
  );
};
