import { SingleTextFieldSubmit } from "./SingleTextFieldSubmit";
import { CPCard } from "./CPCard";

interface CardContentTeamFindProps {
  email?: string;
  onClickFindTeam?: (email: string) => void;
  errorMessage?: string;
  loading?: boolean;
}

export const CardContentTeamFind = ({
  onClickFindTeam,
  ...props
}: CardContentTeamFindProps) => {
  return (
    <CPCard>
      <SingleTextFieldSubmit
        title="Find your team URL"
        hint="We'll send you an email to confirm your email address and find existing CupixWorks teams you've joined or can join."
        label="email"
        labelButton="send"
        value={props.email}
        onClick={onClickFindTeam}
        errorMessage={props.errorMessage}
        loading={props.loading}
      />
    </CPCard>
  );
};
