import { Box, BoxProps, Button, Stack, useMediaQuery, useTheme } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from "react";
import { Footer } from "./Footer";
import { RegionSelect } from "./RegionSelect";
import { Region } from "../types/Region";

interface CPPageBodyProps extends BoxProps {
  children: React.ReactNode;
  regions?: Region[];
  defaultRegion?: Region;
  showRegion?: boolean;
  onRegionChanged?: (region: Region) => void;
  onBackClicked?: () => void;
}

export const CPPageBody: React.FC<CPPageBodyProps> = ({ 
  children, 
  regions = [],
  defaultRegion,
  showRegion = false,
  onRegionChanged = () => {},
  onBackClicked,
  ...props 
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const py = isMobile ? 1.5 : 7.5;

  return (
    <Stack
      component="main"
      className="w-100 h-100 cp-background"
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      spacing={1.5}
      py={py}
      {...props}
    >
      <Box>
        <Stack
          className="cp-resp-sign-card"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: 1 }}
        >

          <Button
            color="black"
            startIcon={<ArrowBackIcon />}
            onClick={onBackClicked}
            sx={{ visibility: onBackClicked ? 'visible' : 'hidden' }}
          >
            Back
          </Button>

          <RegionSelect
            regions={regions}
            defaultRegion={defaultRegion}
            onRegionChanged={onRegionChanged}
            sx={{ visibility: showRegion ? 'visible' : 'hidden' }}
          />

        </Stack>

        {children}
      </Box>
      <Footer />
    </Stack>
  );
};