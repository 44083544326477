import ky from 'ky';

interface TeamResponse {
  result: {
    data: {
      id: string;
      type: string;
      attributes: {
        id: number;
        name: string;
        email_domain: string;
        domain: string;
        use_self_signup: boolean;
        logo_urls: string;
        sign_in_methods: string[];
        locale: string;
      }
    }
  }
}

interface SessionResponse {
  result: {
    data: {
      id: string;
      type: string;
      attributes: {
        user: {
          id: string
          name: string;
          attributes: {
            state: string;
          }
        },
        team: {
          id: string;
          name: string;
          attributes: {
            domain: string;
            state: string;
          }
        },
      }
    }
  }
}

class CupixWorksApi{
  private static instance: CupixWorksApi;

  private static kyInstance() {
    return ky.create({
      timeout: 60000, // 60 seconds
      hooks: {
        beforeError: [
          error => {
            error.response.text().then((errorBody: any) => console.error(JSON.stringify(errorBody)));
            return error;
          }
        ],
        beforeRetry: [
          ({request, options, error, retryCount}) => {
            console.error("trying to retry request: ", {
              url: request.url,
              retryCount: retryCount,
              message: error.message
            });
          }
        ]
      }
    });
  }

  private baseUrl: string;

  public constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public findTeamByDomain(domain: string) {
    const fields = [
      'id',
      'name',
      'email_domain',
      'domain',
      'use_self_signup',
      'logo_urls',
      'sign_in_methods',
      'locale',
    ].join(',');
    return CupixWorksApi.kyInstance().get(`${this.baseUrl}/api/v1/teams/find_by_domain/${domain}?fields=${fields}`)
            .json<TeamResponse>()
            .then((response) => response.result.data.attributes);
  }

  public requestMyTeam(email: string) {
    const encodedEmail = encodeURIComponent(email.toLowerCase());
    return CupixWorksApi.kyInstance().get(`${this.baseUrl}/api/v1/accounts/request_my_teams?email=${encodedEmail}`).json();
  }

  public getSession(teamDomain: string, accessToken: string) {
    const fields = ["user", "team"].join(',');
    return CupixWorksApi.kyInstance().get(`${this.baseUrl}/api/v1/sessions?fields=${fields}`,
                  {
                    headers: {
                      'x-cupix-team-domain': teamDomain,
                      'x-cupix-auth': accessToken,
                    }
                  })
            .json<SessionResponse>()
            .then((response) => response.result.data.attributes);
  }
}

export default CupixWorksApi;