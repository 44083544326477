import { createTheme } from "@mui/material";

declare module '@mui/material/styles' {
  interface Palette {
    black: Palette['primary'];
  }

  interface PaletteOptions {
    black?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    black: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: "#1E88E5",
    },
    black: {
      main: "#414141",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: "16px",
        },
      },
    },
  },
});
