import Box from "@mui/material/Box";
import { Button, Stack, Typography } from "@mui/material";

import IconMEI from "../assets/images/logo_signin_microsoft.png";
import IconAAD from "../assets/images/logo_signin_azure.png";
import IconNTT from "../assets/images/logo_signin_ntt.png";
import IconAMZ from "../assets/images/logo_signin_amazon.png";
import { SignInMethodConfig, SignInMethodKeys } from "../types/SignInMethod";

interface signInWithAppTemplateProps {
  config: SignInMethodConfig[];
}

const signInMethodData = {
  [SignInMethodKeys.MEI]: {
    icon: <img src={IconMEI} alt="Microsoft entra id" />,
    text: "Microsoft Entra ID",
  },
  [SignInMethodKeys.AAD]: {
    icon: <img src={IconAAD} alt="Azure Active Directory" />,
    text: "Azure Active Directory",
  },
  [SignInMethodKeys.NTT]: {
    icon: <img src={IconNTT} alt="NTT Docomo" />,
    text: "NTT Docomo",
  },
  [SignInMethodKeys.AMZ]: {
    icon: <img src={IconAMZ} alt="Amazon" width={18} />,
    text: "Amazon",
  },
};

export const SignInWithApp = ({ config = [] }: signInWithAppTemplateProps) => {
  const validSignInMethods = config.filter(method => signInMethodData[method.type]);

  return (
    <Box className="card-row">
      <Stack spacing={2}>
        {validSignInMethods.map((method) => (
          <Button
            key={method.type}
            variant="outlined"
            className="w-100"
            onClick={() => {
              method.onClick(method.identityProvider);
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className="w-100"
            >
              {signInMethodData[method.type].icon}

              <Typography className="w-100" fontSize={14}>
                Continue with {signInMethodData[method.type].text}
              </Typography>
            </Stack>
          </Button>
        ))}
      </Stack>
    </Box>
  );
};
