import { CardContentResetPasswordCode } from "../components/CardContentResetPasswordCode";
import { useAuth } from "../hooks/useAuth";
import parseQueryString, { parseAmplifyConfig } from "../libs/query_parser";
import { Navigate,  useNavigate,  useSearchParams } from "react-router-dom";
import { cloneSearchParams } from "../libs/utils";
import { useState } from "react";
import { CPPageBody } from "../components/CPPageBody";
import { DIGIT_CODE_ERROR_EMPTY, DIGIT_CODE_ERROR_FAILED, DIGIT_CODE_ERROR_SHORT } from "../assets/strings/en";

const defaultQueryString: {[key: string]: string} = {
  region: "",
  email: "",
}

export default function ResetPasswordCode() {
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const queryString = parseQueryString(searchParams, defaultQueryString);
  const amplifyConfig = parseAmplifyConfig(queryString);
  auth.configureAmplify(amplifyConfig);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const configureFailed = () => {
    if (!auth.isConfigured) {
      console.error("Auth is not configured.");
      return true;
    }
    return false;
  }
  
  if (queryString.email === "") {
    return <Navigate to="/" replace={true}/>;
  }

  const onClickSendCode = async (code: string) => {
    if (configureFailed()) {
      return;
    }

    if (code.length === 0) {
      setErrorMessage(DIGIT_CODE_ERROR_EMPTY);
      return;
    }

    if (code.length !== 6) {
      setErrorMessage(DIGIT_CODE_ERROR_SHORT);
      return;
    }

    setLoading(true);
    const result = await auth.confirmVerificationCode(queryString.email, code, amplifyConfig);
    if (result.success) {
      const params = cloneSearchParams(searchParams);
      params.set("verfication_code", code);

      navigate(`/reset_password/submit?${params.toString()}`);
    } else {
      if (result.message.includes("Invalid verification code provided")) {
        setErrorMessage(DIGIT_CODE_ERROR_FAILED);
      } else {
        setErrorMessage(result.message);
      }
    }
    setLoading(false);
  };

  return (
    <CPPageBody
      onBackClicked={() => {
        navigate(`/?${searchParams.toString()}`);
      }}
    >
      <CardContentResetPasswordCode
        email={queryString.email}
        teamDomain={queryString.team_domain}
        onClickSendCode={onClickSendCode}
        errorMessage={errorMessage}
        loading={loading}
      />
    </CPPageBody>
  );
}