export const cloneSearchParams = (searchParams: URLSearchParams) => {
  const cloned = new URLSearchParams();
  for (const [key, value] of searchParams.entries()) {
    cloned.append(key, value);
  }
  return cloned;
}

export const isEmailFormat = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

const COGNITO_COOKIE_PREFIX = "CognitoIdentityServiceProvider";
export const removeAllCognitoCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf("=");
    let name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;

    // If the cookie name starts with "Cognito", delete it
    if (name.trim().startsWith(COGNITO_COOKIE_PREFIX)) {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.${process.env.REACT_APP_AUTH_COOKIE_STORAGE_DOMAIN}`;
    }
  }
}

export const capitalize = (str: string, onlyFirstLetter = false) => {
  if (onlyFirstLetter) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export const emailRegex = /\S+@\S+\.\S+/;